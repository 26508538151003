import React from "react";
import Image from "next/image";

interface Data {
  data: {
    banner_id: Number;
    image: String;
    message: String;
    type: String;
    url: String;
    language: String;
    created_at: String;
  };
}
function Banner(data: Data) {
  return (
    <div className="pera1-section block">
      <section className="wrap" id="back-to-top-anchor">
        {data.data.type == "Video" && (
          <div className="video-bg">
            <iframe
              src={`https://www.youtube.com/embed/${data.data.url}?autoplay=1&mute=1&playsinline=1&loop=1&controls=0&disablekb=1`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
        {data.data.type == "Image" && (
          <div className="video-bg">
            <Image
              src={`${data.data.image}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </div>
        )}
        <div className="content-banner">
          <div className="banner-inner">
            <p dangerouslySetInnerHTML={{ __html: data.data.message }}></p>
          </div>
          {/* <div className="banner-inner">
            <p>The excitement returns to Thailand!</p>
            <h1 className="title">
              Japan Volleyball Asia Tour in Thailand 2024
            </h1>
            <h2>Now officially scheduled!</h2>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Banner;
