import React from "react";
import Image from "next/image";
import Logo from "@/public/static/images/logo.jpg";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "next/link";
import { useRouter } from "next/router";
import Stack from "@mui/material/Stack";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import ScrollToTopFab from "@/components/th/ScrollToTopFab";

const drawerWidth = 280;
const navItems = [
  { link: "#TournamentOverview", component: "ภาพรวมการแข่งขัน" },
  { link: "/#MatchVenue", component: "สถานที่แข่งขัน" },
  { link: "/#OfficialSNS", component: "SNS อย่างเป็นทางการ" },
  { link: "/#FanEvents", component: "กิจกรรมสำหรับแฟนๆ" },
  { link: "/#PTH", component: "ไฮไลท์การแข่งขันครั้งก่อน" },
];

interface Data {
  data: {
    id: Number;
    message: String;
    facebook: String;
    instagram: String;
    language: String;
    created_at: String;
  };
}

function Header(props: Data) {
  const router = useRouter();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname, asPath, query } = router;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Divider />
      <List>
        {navItems.map((item, index) => {
          return (
            <Link href={item.link} key={index}>
              <ListItem key={index}>{item.component}</ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );

  return (
    <header className="fixed-top shadow">
      <div className="container-fluid bg-b px-5">
        <div className="row">
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              justifyContent: "end",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "baseline",
                mr: 4,
              }}
              className="sns py-2"
            >
              <p>FOLLOW US:</p>
              <Link
                href={`${props.data.facebook}`}
                // href="https://www.facebook.com/JapanVolleyball.AsiaTour"
                className="icon-white"
                target="_blank"
              >
                <FacebookIcon />
              </Link>
              <Link
                // href="https://www.instagram.com/japanvolleyball_th/"
                href={`${props.data.instagram}`}
                className="icon-white"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </Stack>
            <div className="btn-ticket py-2 px-4">
              <Link href="">การซื้อตั๋ว (อยู่ระหว่างการเตรียมการ)</Link>
            </div>
          </Box>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <Toolbar sx={{ py: 2, alignItems: "center" }} className="toolbar">
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <Image
                src={Logo}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="logo"
                width={70}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <nav className="menu">
                <ul>
                  <li>
                    <Link className="nav_link" href="/#TournamentOverview">
                      ภาพรวมการแข่งขัน
                    </Link>
                  </li>
                  <li>
                    <Link className="nav_link" href="/#MatchVenue">
                      สถานที่แข่งขัน
                    </Link>
                  </li>
                  <li>
                    <Link className="nav_link" href="/#OfficialSNS">
                      SNS อย่างเป็นทางการ
                    </Link>
                  </li>
                  <li>
                    <Link className="nav_link" href="/#FanEvents">
                      กิจกรรมสำหรับแฟนๆ
                    </Link>
                  </li>
                  <li>
                    <Link className="nav_link" href="/#PTH">
                      ไฮไลท์การแข่งขันครั้งก่อน
                    </Link>
                  </li>
                  <li></li>
                </ul>
              </nav>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                sx={{ alignItems: "center", ml: 0, mr: 1 }}
                className="lang-button"
              >
                <Link href="/" className="active">
                  TH
                </Link>
                <Link href="/en/">EN</Link>
                <Link href="/jp/">JP</Link>
              </Stack>
            </Box>

            <Box sx={{ flexGrow: 0 }}></Box>

            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, p: 0 }}
            >
              <Box className="mo-logo" sx={{ mr: 1, width: "40%" }}>
                <Link href="/en/">
                  <Image
                    src={Logo}
                    alt="Japan Volleyball Asia Tour in Thailand"
                    className="logo"
                    width={60}
                  />
                </Link>
              </Box>

              <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                  sx={{ alignItems: "center", ml: 0, mr: 1 }}
                  className="lang-button"
                >
                  <Link href="/" className="active">
                    TH
                  </Link>
                  <Link href="/en/">EN</Link>
                  <Link href="/jp/">JP</Link>
                </Stack>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                  className="mb_menu"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>

            <Box component="nav">
              <Drawer
                className="drawer"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { sm: "block", md: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                  "& .MuiPaper-root": {
                    backgroundColor: "#000",
                    color: "#fff",
                  },
                }}
              >
                {drawer}
                <div className="btn-ticket py-2 px-4 text-center">
                  <Link href="">
                    การซื้อตั๋ว
                    <br />
                    (อยู่ระหว่างการเตรียมการ)
                  </Link>
                </div>
              </Drawer>
            </Box>
          </Toolbar>
        </div>
      </div>
      <ScrollToTopFab />
    </header>
  );
}

export default Header;
