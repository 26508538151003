import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import Image from "next/image";
// import img3 from "@/public/static/images/champion.jpg";
// import img4 from "@/public/static/images/match_01.jpg";
// import img5 from "@/public/static/images/match_02.jpg";
// import img6 from "@/public/static/images/match_03.jpg";
// import img7 from "@/public/static/images/match_04.jpg";

// import img8 from "@/public/static/images/team_01.jpg";
// import img9 from "@/public/static/images/team_02.jpg";
// import img10 from "@/public/static/images/team_03.jpg";
// import img11 from "@/public/static/images/team_04.jpg";

// import img12 from "@/public/static/images/fanmeeting.jpg";

import Gallery from "@/components/Gallery";
interface Data {
  previous: Previous;
  fanMeet: Fanmeet;
}

interface Previous {
  id: Number;
  header_message: String;
  details: String;
  image: String;
  thumbnail_1: String;
  thumbnail_2: String;
  thumbnail_3: String;
  thumbnail_4: String;
  topic: String;
  participating_thumbnail_1: String;
  participating_thumbnail_2: String;
  participating_thumbnail_3: String;
  participating_thumbnail_4: String;
  text_thumnail_1: String;
  text_thumnail_2: String;
  text_thumnail_3: String;
  text_thumnail_4: String;
  text_participating_thumnail_1: String;
  text_participating_thumnail_2: String;
  text_participating_thumnail_3: String;
  text_participating_thumnail_4: String;
  language: String;
  created_at: String;
}
interface Video {
  video_topic: String;
  video: String;
}
interface Fanmeet {
  id: Number;
  message: String;
  media_message: String;
  image: String;
  gallery_1: String;
  gallery_2: String;
  gallery_3: String;
  gallery_4: String;
  gallery_5: String;
  gallery_6: String;
  gallery_7: String;
  gallery_8: String;
  gallery_9: String;
  gallery_10: String;
  gallery_11: String;
  gallery_12: String;
  gallery_13: String;
  gallery_14: String;
  gallery_15: String;
  language: String;
  created_at: String;
  video: Video[];
}

function PreviousTournament(props: Data) {
  const previous: Previous = props.previous;
  const fanMeet: Fanmeet = props.fanMeet;
  return (
    <section id="PTH" className="section previoustournament">
      <div className="container-fluid black-b py-5">
        <div className="row">
          <div className="col-12 text-center">
            <h4 className="text-heading_section">{previous.header_message}</h4>
          </div>
        </div>
      </div>

      <div className="red-b">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p dangerouslySetInnerHTML={{ __html: previous.details }}></p>
              {/* <h4 className="text-center mb-4">
                ผลการแข่งขัน Japan Volleyball Asia Tour in Thailand 2023
              </h4>
              <h1 className="text-center fw-bold my-5 text-shadow">
                Champion: Wolfdogs Nagoya
              </h1>
              <h6 className="text-center">
                Runner-up: Nakhon Ratchasima Volleyball Club (Cat Devils) / 3rd
                Place: Panasonic Panthers / 4th Place: Suntory Sunbirds
              </h6> */}
            </div>
            <div className="col-12">
              <Image
                src={`${previous.image}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-5"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.thumbnail_1}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_thumnail_1}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.thumbnail_2}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_thumnail_2}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.thumbnail_3}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_thumnail_3}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.thumbnail_4}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_thumnail_4}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="darkgary-b py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3">
              <h1 className="text-center fw-bold my-5 mb-3 text-shadow">
                {previous.topic}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.participating_thumbnail_1}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_participating_thumnail_1}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.participating_thumbnail_2}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_participating_thumnail_2}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.participating_thumbnail_3}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_participating_thumnail_3}
              </h5>
            </div>
            <div className="col-12 col-md-6 col-lg-3 previous_match">
              <Image
                src={`${previous.participating_thumbnail_4}`}
                width={0}
                height={0}
                alt="Japan Volleyball Asia Tour in Thailand"
                className="img my-4"
                sizes="100vw"
                // Make the image display full width
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
              <h5 className="text-center fw-bold">
                {previous.text_participating_thumnail_4}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="img-b">
        <Image
          src={`${fanMeet.image}`}
          width={0}
          height={0}
          alt="Japan Volleyball Asia Tour in Thailand"
          className="img"
          sizes="100vw"
          // Make the image display full width
          style={{
            width: "100%",
            height: "auto",
          }}
          loading="lazy"
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 heading_section text-center match-inner mt-5">
            <div className="heading_inner py-5 pb-3">
              <div className="heading-title">
                <h1 className="heading-title_m heading-title_m-center mb-3">
                  <CircleIcon />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: fanMeet.message,
                    }}
                  ></span>
                  <CircleIcon />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <Gallery data={fanMeet} />
        <div className="row">
          <div className="col-12 heading_section text-center match-inner mt-5">
            <div className="heading_inner py-5 pb-3">
              <div className="heading-title">
                <h1 className="heading-title_m heading-title_m-center mb-3">
                  <CircleIcon />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: fanMeet.media_message,
                    }}
                  ></span>
                  <CircleIcon />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {fanMeet.video.map((element: any, index: any) => {
            return (
              <div className="col-12 col-lg-6 video-a">
                <iframe
                  width="100%"
                  height="400"
                  src={`https://www.youtube.com/embed/${element.video}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <h5 className="text-center mt-3">{element.video_topic}</h5>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default PreviousTournament;
