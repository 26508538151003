import React from "react";
import Image from "next/image";
import CircleIcon from "@mui/icons-material/Circle";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
// import img1 from "@/public/static/images/tournament-img.jpg";

interface Data {
  data: {
    id: Number;
    header_message: String;
    highlight_message: String;
    detail: String;
    image: String;
    language: String;
    created_at: String;
  };
}

function TournamentOverview(data: Data) {
  return (
    <section id="TournamentOverview" className="section tournament">
      <div className="container">
        <div className="row">
          <div className="col-12 heading_section">
            <div className="heading_inner">
              <div className="heading-subtitle-wrapper heading-align-center heading-deco-wrapper">
                <span className="heading-subtitle">
                  <span className="heading-deco-inner left-part">
                    <span className="heading-deco-icon">
                      <span className="content-icon-wrapper">
                        <EmojiEventsIcon />
                      </span>
                    </span>
                  </span>
                  ภาพรวมการแข่งขัน
                </span>
              </div>
              <div className="heading-title">
                <h1 className="heading-title_m">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.data.header_message,
                    }}
                  ></p>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="box_text-black">
              <div className="text-content_highlight">
                <p className="text-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.data.highlight_message,
                    }}
                  ></p>
                  {/* วูลฟ์ด็อกส์ นาโกย่า ทีมชนะเลิศจากการแข่งขันครั้งก่อน, ซันทอรี
                  ซันเบิร์ดส์ แชมป์ V ลีกฤดูกาลนี้และพานาโซนิค แพนเทอร์ส
                  <br />
                  ทีมที่ชนะเลิศถ้วยพระราชทานสมเด็จพระจักรพรรดิ
                  จะกลับมาพบกันอีกครั้งที่ประเทศไทยเพื่อชิงตำแหน่งสโมสรที่แข็งแกร่งที่สุดในญี่ปุ่นและไทย! */}
                </p>
              </div>
              <div className="icon-position_right">
                <FormatQuoteOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-3 align-items-center">
          <div className="col-12 col-lg-6">
            <div className="content-text">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.data.detail,
                }}
              ></p>
              {/* <p>
                <strong>ชื่อการแข่งขัน : </strong> Japan Volleyball Asia Tour in
                Thailand 2024 "Panasonic ENERGY Cup"
              </p>

              <p>
                <strong>วันที่จัดการแข่งขัน : </strong> 14 กันยายน 2024
                (วันเสาร์), 15 กันยายน 2024 (วันอาทิตย์)
              </p>

              <p>
                <strong>สถานที่แข่งขัน : </strong> สนามนิมิบุตร (กรุงเทพฯ)
              </p>

              <p>
                <strong>ทีมที่คาดว่าจะเข้าร่วม : </strong> พานาโซนิค แพนเทอร์ส,
                ซันทอรี ซันเบิร์ดส์, วูลฟ์ด็อกส์ นาโกย่า, ทีมไทย
                (อยู่ระหว่างการเจรจา)
              </p>

              <p>
                <strong>
                  * Wolfdogs Nagoya อาจไม่สามารถเข้าร่วมการแข่งขัน Asian
                  Volleyball Club Championship 2024 ได้
                  หากกำหนดการแข่งขันซ้อนทับกัน
                  หลังจากมีการยืนยันวันแข่งขันที่แน่นอนแล้ว
                  เราจะแจ้งให้ทราบอีกครั้งในเว็บไซต์นี้
                </strong>
              </p>

              <p>
                <strong>ผู้จัดงาน : </strong> OSAKA BLUTEON (Panasonic
                Panthers), SUNTORY SUNBIRDS OSAKA, WOLFDOGS NAGOYA, Nakhon
                Ratchasima VC ( Cat Devil ){" "}
              </p>

              <p>
                <strong>ความร่วมมือ : </strong>Thailand Volleyball Association
              </p>

              <p>
                <strong>การสนับสนุน : </strong>Public Interest Incorporated
                Foundation Japan Volleyball Association (planned), Japan
                Volleyball League Corporation
              </p>

              <p>
                <strong>กติกาการแข่งขัน : </strong>{" "}
                การแข่งขันแบบทัวร์นาเมนต์ระหว่าง 4 ทีม
              </p>

              <p>
                <strong>การขายตั๋ว :</strong>{" "}
                จะมีการประกาศอย่างเป็นทางการหลังจากยืนยันกับบริษัทขายตั๋วในประเทศไทย
              </p>

              <p>
                <strong>พาร์ทเนอร์หลักอย่างเป็นทางการ :</strong> Panasonic
                ENERGY Co.,Ltd.
              </p> */}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <Image
              src={`${data.data.image}`}
              width={0}
              height={0}
              // src={img1}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TournamentOverview;
