import { NextPage } from "next/types";
import React, { useState } from "react";
import { appDispatch, appSelector } from "@/store/hooks";
import Header from "@/components/th/Header";
import Banner from "@/components/th/Banner";
import Tournament from "@/components/th/TournamentOverview";
import MatchVenue from "@/components/th/Matchvenue";
import SNS from "@/components/th/Sns";
import Fanevent from "@/components/th/Fanevent";
import PreviousTournament from "@/components/th/PreviousTournament";
import Footer from "@/components/th/Footer";
import {
  fetchBanner,
  fetchFooter,
  fetchFanEvents,
  fetchFanMeeting,
  fetchMatchVenue,
  fetchOfficeSNS,
  fetchPreviousTournament,
  fetchTournamentOverview,
} from "@/features/fetchData";

interface Data {
  banner: [];
  footer: [];
  fanEvents: [];
  fanMeeting: [];
  matchVenue: [];
  officeSNS: [];
  previousTournament: [];
  tournamentOverview: [];
}

function Index(props: any) {
  return (
    <div className="wrapper th">
      <Header data={props.officeSNS} />
      <Banner data={props.banner} />
      <Tournament data={props.tournamentOverview} />
      <MatchVenue data={props.matchVenue} />
      <SNS data={props.officeSNS} />
      <Fanevent data={props.fanEvents} />
      <PreviousTournament
        previous={props.previousTournament}
        fanMeet={props.fanMeeting}
      />
      <Footer data={props.footer} />
    </div>
  );
}

export const getServerSideProps = async (context: any) => {
  const language = "Thailand";
  try {
    const data: Data = await Promise.all([
      fetchBanner(language, context),
      fetchFooter(language, context),
      fetchFanEvents(language, context),
      fetchFanMeeting(language, context),
      fetchMatchVenue(language, context),
      fetchOfficeSNS(language, context),
      fetchPreviousTournament(language, context),
      fetchTournamentOverview(language, context),
    ]).then(
      ([
        banner,
        footer,
        fanEvents,
        fanMeeting,
        matchVenue,
        officeSNS,
        previousTournament,
        tournamentOverview,
      ]) => {
        return {
          banner: banner.data,
          footer: footer.data,
          fanEvents: fanEvents.data,
          fanMeeting: fanMeeting.data,
          matchVenue: matchVenue.data,
          officeSNS: officeSNS.data,
          previousTournament: previousTournament.data,
          tournamentOverview: tournamentOverview.data,
        };
      }
    );
    return {
      props: {
        banner: data.banner,
        footer: data.footer,
        fanEvents: data.fanEvents,
        fanMeeting: data.fanMeeting,
        matchVenue: data.matchVenue,
        officeSNS: data.officeSNS,
        previousTournament: data.previousTournament,
        tournamentOverview: data.tournamentOverview,
      },
    };
  } catch {
    return {
      props: {
        banner: [],
        footer: [],
        fanEvents: [],
        fanMeeting: [],
        matchVenue: [],
      },
    };
  }
};

export default Index;
