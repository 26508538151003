import React from "react";
import Image from "next/image";
import CelebrationIcon from "@mui/icons-material/Celebration";
// import img2 from "@/public/static/images/fan.jpg";
import Link from "next/link";

interface Data {
  data: {
    id: Number;
    message: String;
    image: String;
    language: String;
    created_at: String;
  };
}

function Fanevent(data: Data) {
  return (
    <section id="FanEvents" className="section fanevent">
      <div className="container">
        <div className="row">
          <div className="col-12 heading_section">
            <div className="heading_inner">
              <div className="heading-subtitle-wrapper heading-align-center heading-deco-wrapper">
                <span className="heading-subtitle">
                  <span className="heading-deco-inner left-part">
                    <span className="heading-deco-icon">
                      <span className="content-icon-wrapper">
                        <CelebrationIcon />
                      </span>
                    </span>
                  </span>
                  กิจกรรมสำหรับแฟนๆ
                </span>
              </div>
              {/* <div className="heading-title">
                <h1 className="heading-title_m">Fan Meeting 2024</h1>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7">
            <p dangerouslySetInnerHTML={{ __html: data.data.message }}></p>
            {/* <h5>งาน Fan Meet ประกาศแล้ว! </h5>
            <p>
              เรายินดีที่จะแจ้งให้ทราบว่าวันจัดงาน Fan Meet Event ของ Japan
              Volleyball Asia Tour in Thailand 2024 ได้ถูกกำหนดแล้ว
            </p>
            <p>
              ด้วยตารางของแต่ละทีม
              งานแฟนมีตจึงถูกเลื่อนจากวันเดิมที่วางไว้คือวันเสาร์ที่ 22
              มิถุนายนไปเป็นวันเสาร์ที่ 29 มิถุนายน
              พวกเราตั้งตารอที่จะได้พบกับทุกคนที่สถานที่จัดงาน!
            </p>
            <div className="dec-inner-red">
              <h4 className="mb-3">รายละเอียด :</h4>
              <p>
                <strong>วันที่ : 29 มิถุนายน 2567 (วันเสาร์)</strong>
              </p>
              <p>13:30 น. – แถลงข่าว </p>
              <p>15:15 น. – Fan Meet Event </p>
              <p>
                <small>
                  *หมายเหตุ : วันที่ได้มีการเปลี่ยนแปลงจากแผนเดิมในวันที่ 22
                  มิถุนายน 2567
                </small>
              </p>
            </div>
            <div className="dec-inner-gray">
              <p>
                <strong>สถานที่จัดงาน :</strong> Q stadium ชั้น M (Emquartier)
              </p>
              <p>
                <strong>ที่อยู่ :</strong> 693, 695 ถนนสุขุมวิท, กรุงเทพฯ 10110
                ประเทศไทย
              </p>
              <p>
                <strong>Google Map : </strong>
                <Link href="https://maps.app.goo.gl/muXq2jVmkdU8TMBc6">
                  https://maps.app.goo.gl/muXq2jVmkdU8TMBc6
                </Link>
              </p>
              <hr />
              <h5 className="mb-3 fw-bold">รายละเอียดของงาน :</h5>
              <p>
                <strong>งานแถลงข่าว :</strong> เวลา 13:30 น. ถึง 14:30 น.
                (ตามแผน)
              </p>
              <p>
                <strong>รายละเอียด :</strong> ภาพรวมของการแข่งขัน,
                การจับสลากกำหนดคู่แข่งขัน
              </p>
              <p>
                <strong>ผู้ชม :</strong> สื่อมวลชนเท่านั้น
              </p>
              <hr />
              <h5 className="mb-3 fw-bold">Fan Meet Event</h5>
              <p>
                <strong>เวลา :</strong> 15:15 น. ถึง 16:30 น. (ตามแผน)
              </p>
              <p>
                <strong>กิจกรรม :</strong> สัมภาษณ์นักกีฬา, แข่งเป่ายิงฉุ่บ,
                การจับฉลาก, กิจกรรมถ่ายรูปร่วมกับนักกีฬา, และอื่นๆ
              </p>
              <p>
                <strong>ผู้ชม :</strong> ผู้เข้าชมรวม 400 ท่าน (โดย 200 ท่านแรก
                จะได้สิทธิ์นั่ง และ 200
                ท่านถัดมาจะได้เข้าร่วมงานโดยทางงานจะจัดพื้นที่สำหรับยืนในห้องไว้ให้
                เนื่องจากข้อจำกัดด้านสถานที่)
              </p>
              <p>
                <strong>วิธีการเข้าร่วม :</strong>{" "}
                จะแจกบัตรคิวในวันงานโดยเรียงลำดับตามการมาถึง
              </p>
              <p className="text-danger">
                <small>
                  *รายละเอียดเกี่ยวกับสถานที่และเวลาในการแจกบัตรจะประกาศในภายหลังทาง
                  SNS อย่างเป็นทางการของเรา
                </small>
              </p>
              <hr />
              <h5 className="mb-3 fw-bold">นักกีฬาที่เข้าร่วม</h5>
              <p>
                OSAKA BLUTEON (Panasonic PANTHERS) – นาย ชุนสุเกะ นากามุระ, นาย
                เคตาโร นิชิกาวะ
              </p>
              <p>
                SUNTORY SUNBIRDS OSAKA – นาย รุย ทาคาฮาชิ, นาย ทัตสึกิ คาชิวาดะ
              </p>
              <p>WOLFDOGS NAGOYA – นาย เคนตะ อิจิกาวะ</p>
              <p className="text-danger">
                <small>
                  *การเข้าร่วมของนักกีฬา
                  อาจมีการเปลี่ยนแปลงขึ้นอยู่กับสภาพร่างกายของนักกีฬา
                </small>
              </p>
            </div>

            <p>
              <strong>For inquiries : </strong>
              Japan Volleyball Asia Tour in Thailand 2024
              <br />
              Organizing Committee ITP Asia Co., Ltd.
            </p>
            <p>
              <strong>Email : </strong>
              <Link href="mailto:asiatour@itp.co.th">asiatour@itp.co.th</Link>
            </p> */}
          </div>
          <div className="col-12 col-lg-5">
            <Image
              src={`${data.data.image}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Fanevent;
