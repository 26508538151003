import React, { useRef, useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Image from "next/image";
// import gallery1 from "@/public/static/images/meet_01.jpg";
// import gallery2 from "@/public/static/images/meet_02.jpg";
// import gallery3 from "@/public/static/images/meet_03.jpg";
// import gallery4 from "@/public/static/images/meet_04.jpg";
// import gallery5 from "@/public/static/images/meet_05.jpg";
// import gallery6 from "@/public/static/images/meet_06.jpg";
// import gallery7 from "@/public/static/images/meet_07.jpg";
// import gallery8 from "@/public/static/images/meet_08.jpg";
// import gallery9 from "@/public/static/images/meet_09.jpg";
// import gallery10 from "@/public/static/images/meet_10.jpg";
// import gallery11 from "@/public/static/images/meet_11.jpg";
// import gallery12 from "@/public/static/images/meet_12.jpg";
// import gallery13 from "@/public/static/images/meet_13.jpg";
// import gallery14 from "@/public/static/images/meet_14.jpg";
// import gallery15 from "@/public/static/images/meet_15.jpg";

import Fancybox from "@/components/th/Fancybox";

interface Data {
  data: {
    id: Number;
    message: String;
    image: String;
    gallery_1: String;
    gallery_2: String;
    gallery_3: String;
    gallery_4: String;
    gallery_5: String;
    gallery_6: String;
    gallery_7: String;
    gallery_8: String;
    gallery_9: String;
    gallery_10: String;
    gallery_11: String;
    gallery_12: String;
    gallery_13: String;
    gallery_14: String;
    gallery_15: String;
    language: String;
    created_at: String;
  };
}

function Gallery(props: Data) {
  return (
    <div className="row">
      <div className="gallery">
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_1}`}
          >
            <Image
              src={`${props.data.gallery_1}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_2}`}
          >
            <Image
              src={`${props.data.gallery_2}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_3}`}
          >
            <Image
              src={`${props.data.gallery_3}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_4}`}
          >
            <Image
              src={`${props.data.gallery_4}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_5}`}
          >
            <Image
              src={`${props.data.gallery_5}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_6}`}
          >
            <Image
              src={`${props.data.gallery_6}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_7}`}
          >
            <Image
              src={`${props.data.gallery_7}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_8}`}
          >
            <Image
              src={`${props.data.gallery_8}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_9}`}
          >
            <Image
              src={`${props.data.gallery_9}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_10}`}
          >
            <Image
              src={`${props.data.gallery_10}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_11}`}
          >
            <Image
              src={`${props.data.gallery_11}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_12}`}
          >
            <Image
              src={`${props.data.gallery_12}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_13}`}
          >
            <Image
              src={`${props.data.gallery_13}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_14}`}
          >
            <Image
              src={`${props.data.gallery_14}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
          <a
            className="gallery-inner"
            data-fancybox="gallery"
            href={`${props.data.gallery_15}`}
          >
            <Image
              src={`${props.data.gallery_15}`}
              width={0}
              height={0}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </a>
        </Fancybox>
      </div>
    </div>
  );
}

export default Gallery;
