import React from "react";
import Image from "next/image";
import CircleIcon from "@mui/icons-material/Circle";
// import map from "@/public/static/images/map.jpg";

interface Data {
  data: {
    id: Number;
    nearest_station: String;
    topic: String;
    venue_name: String;
    detail_of_venue: String;
    location: String;
    google_map: String;
    image: String;
    language: String;
    created_at: String;
  };
}

function Matchvenue(data: Data) {
  return (
    <section id="MatchVenue" className="section match gray-b">
      <div className="icon_background-text-holder-one">
        <span className="icon_background-text">
          <span className="icon_character">Match</span>
        </span>
      </div>
      <div className="icon_background-text-holder">
        <span className="icon_background-text">
          <span className="icon_character">Venue</span>
        </span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 heading_section text-center match-inner">
            <div className="heading_inner">
              <div className="heading-title">
                <h1 className="heading-title_m heading-title_m-center mb-3">
                  <CircleIcon /> Match Venue <CircleIcon />
                </h1>
                <h4>
                  {data.data.topic}
                  {/* สถานที่แข่งขัน */}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12 match-inner">
            <Image
              src={`${data.data.image}`}
              width={0}
              height={0}
              // src={map}
              alt="Japan Volleyball Asia Tour in Thailand"
              className="img"
              sizes="100vw"
              // Make the image display full width
              style={{
                width: "100%",
                height: "auto",
              }}
              loading="lazy"
            />
          </div>
          <div className="col-12 col-lg-6 map_detail match-inner py-5">
            <div className="map_detail-inner mb-3">
              <div className="row">
                <div className="col-12 col-md-3 fw-bold">ชื่อสถานที่ :</div>
                <div className="col-12 col-md-9">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.data.venue_name,
                    }}
                  ></p>
                  <small>{data.data.detail_of_venue}</small>
                  {/* สนามนิมิบุตร
                  <br />
                  <small>
                    *ตั้งอยู่ในพื้นที่เดียวกันกับกรมพลศึกษา
                    กระทรวงการท่องเที่ยวและกีฬา
                  </small> */}
                </div>
              </div>
            </div>
            <div className="map_detail-inner mb-3">
              <div className="row">
                <div className="col-12 col-md-3 fw-bold">ที่ตั้ง :</div>
                <div className="col-12 col-md-9">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.data.location,
                    }}
                  ></p>
                  {/* 154 Rama 1 Road, Wang Mai, Pathum Wan, Bangkok 10330 */}
                </div>
              </div>
            </div>
            <div className="map_detail-inner mb-3">
              <div className="row">
                <div className="col-12 col-md-3 fw-bold">
                  สถานีที่ใกล้ที่สุด :
                </div>
                <div className="col-12 col-md-9">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.data.nearest_station,
                    }}
                  ></p>
                  {/* {data.data.nearest_station} */}
                  {/* สถานีรถไฟฟ้าบีทีเอสสนามกีฬาแห่งชาติ
                  <br />
                  <small>(เดินจากสถานี 1 นาที)</small> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 py-5 match-inner">
            <iframe
              src={`${data.data.google_map}`}
              // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7751.101903718939!2d100.528933!3d13.745615!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2992d5094c26d%3A0xd600bbe785399774!2z44OL44Of44OW44OD44OI44K544K_44K444Ki44Og!5e0!3m2!1sja!2sus!4v1719391146083!5m2!1sja!2sus"
              width="100%"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Matchvenue;
