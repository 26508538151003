import React from "react";
import Link from "next/link";

interface Data {
  data: {
    id: Number;
    message: String;
    language: String;
    created_at: String;
  };
}

function Footer(props: Data) {
  return (
    <section className="section black-b footer">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: props.data.message,
              }}
            ></p>
            {/* <h6 className="mb-4">
              กรณีมีข้อสอบถาม ติดต่อเราได้ที่ Inbox บนเพจ Facebook
              อย่างเป็นทางการหรือที่{" "}
              <Link href="mailto:asiatour@itp.co.th">asiatour@itp.co.th </Link>
              ติดต่อได้ทั้งภาษาญี่ปุ่น ภาษาอังกฤษ และภาษาไทย
            </h6> */}
            <hr />
            <p className="mb-0">
              <small>
                Copyright 2023 JAPAN VOLLEYBALL ASIA TOUR IN THAILAND
              </small>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
